import 'babel-polyfill'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import i18n from './plugins/i18n';
import axios from 'axios'

window.axios = require('axios');

axios.interceptors.request.use(function (config) {
  if (config.url.startsWith('https://web-admin.sum.ba')){
    config.headers.accept = "application/json";
    config.headers.language = localStorage.getItem('language_most') ? localStorage.getItem('language_most') : 'hr'
  }
  else{
    config.headers.accept = "application/json";
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});

require('@/assets/css/app.css')

Vue.config.productionTip = false

var VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo, {
  container: "body",
  duration: 2500,
  offset: -80,
})

new Vue({
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
