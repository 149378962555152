import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
    'hr': {
        items: [
            {
                title: "O nama",
                link: "#opceInformacije",
                icon: "fas fa-info-circle"
            },
            {
                title: "Uredništvo",
                link: "#urednistvo",
                icon: "fas fa-user-edit"
            },
            {
                title: "Arhiva",
                link: "#clanci",
                icon: "fas fa-archive"
            },
            {
                title: "Dokumenti",
                link: "#upute",
                icon: "far fa-clipboard"
            },
            {
                title: "Uredničko vijeće",
                link: "#urednici",
                icon: "fas fa-user-friends"
            },
            {
                title: "Međunarodno znanstveno vijeće",
                link: "#medunarodno",
                icon: "fas fa-users"
            },
            // {
            //   title: "Pretraga Code of Ethics",
            //   link: "#"
            // },
            {
                title: "Kontakt",
                link: "#kontakt",
                icon: "fas fa-phone-square"
            }
        ],
        about: 'O nama',
        about_text: `Počeo je izlaziti 1994. godine kao časopis za humanističke znanosti. Izlazio je dva puta
      godišnje sve do 2010. godine. Sredinom 2014. izabrano je novo uredništvo čiji je prvotni cilj
      bio oživjeti ovaj časopis. Novo je uredništvo proširilo istraživačke interese i na područje
      društvenih znanosti te uz pomoć sunakladnika Instituta društvenih znanosti Ivo Pilar znatno
      promijenilo uređivačku politiku. Osim novoga uredništva Mostariensia je doživjela i još neke
      važne promjene. Naime uz uredništvo oformljeno je i međunarodno znanstveno vijeće čiji su
      članovi mahom priznati i dokazani stručnjaci na području svoga djelovanja. Ovo je tijelo
      recenzentska baza koja je iz broja u broj osnaživana kompetentnim stručnjacima iz zemlje i
      inozemstva.`,
        about_text1: `Mostariensia objavljuje priloge iz različitih društvenih i humanističkih disciplina, ali i one
      koji imaju interdisciplinaran i multidisciplinaran karakter. Primaju se isključivo neobjavljeni
      radovi i oni koji nisu u recenzijskome postupku pri nekome drugom časopisu. Radovi mogu
      biti napisani na hrvatskome, engleskome i njemačkome jeziku.
      Svi radovi koji imaju znanstvena ili stručna obilježja prolaze tzv. dvostruku slijepu recenziju
      (eng. double-blind review) kojom se ne otkriva identitet ni autora ni recenzenta. Nakon
      pozitivnih ocjena radovi se kategoriziraju u jednu od četiriju standardnih kategorija. Osim
      znanstvenih i stručnih radova Mostariensia prima i stručne radove, kao i radove koji ne
      prolaze recenzetski postupak: prikazi, aktualnosti, in memoriam, razni govori i sl.`,
        about_text2: `Časopis izlazi dvaput godišnje.<br>
      Časopis je referiran u sljedećim bazama podataka:<br>
      
      Hrčak – Portal znanstvenih časopisa Republike Hrvatske <a href="https://hrcak.srce.hr/" target="_blank">https://hrcak.srce.hr/</a><br>
      
      EBSCO - Full text online library (<a href="www.ebschohost.com" target="_blank">www.ebschohost.com</a>)<br>
      CEEOL - Central and Eastern European Online Library (<a href="www.ceeol.com" target="_blank">www.ceeol.com</a>)<br>
      
      Slavus - Slavic Humanities Index (<a href="www.slavus.ca" target="_blank">www.slavus.ca</a>)<br>
      
      ISSN 1023-8638`,
        editional_board: 'Uredništvo',
        archive: 'Arhiva',
        instruction: 'Dokumenti',
        instruction1: 'Časopis South Eastern European Journal of Communication znanstvena je publikacija otvorenog pristupa koja objavljuje recenzirane radove. Dostavljeni radovi, koji zadovoljavaju upute o pisanju, podliježu dvostrukoj anonimnoj recenziji - ocjenjuju ih dva recenzenta, pri čemu su identiteti autora i recenzenata obostrano nepoznati. Ukoliko je ocjena jednog od recenzenata negativna, rad će biti upućen na dodatnu recenziju. Temeljem recenzije uredništvo donosi odluku o konačnoj kategorizaciji rada na način njegova prihvaćanja, traženja dorada ili ispravaka, ili odbijanja. Dorađene ili ispravljene radove autori trebaju poslati Uredništvu najkasnije u roku od 8 dana od primitka primjedbi.',
        instruction2: 'U časopisu se objavljuju sljedeće kategorije radova:',
        instruction3: 'izvorni znanstveni rad,',
        instruction4: 'prethodno priopćenje,',
        instruction5: 'pregledni znanstveni rad,',
        instruction6: 'stručni rad.',
        instruction7: 'Iznimno, Uredništvo časopisa može prihvatiti i drugu vrstu rada koji nije kategoriziran, ako se isti bude smatrao korisnim za čitateljstvo.',
        instruction8: 'Svi radovi trebaju biti pisani na engleskom ili hrvatskom jeziku. Dostavljeni tekstovi trebaju biti lektorirani. Obvezno je dostaviti i podatke o prevoditelju i/ili lektoru: ime i prezime, naziv i adresu institucije, adresu e-pošte i broj telefona.',
        instruction9: 'Pri dostavljanju rada autor dostavlja i izjavu da je rad autorsko djelo, da nije već objavljen, niti ponuđen drugom časopisu na objavljivanje. Izneseni stavovi u radu isključiva su odgovornost autora.',
        instruction10: 'Svaki rad treba sadržavati:',
        instruction11: 'ime i prezime i akademski stupanj autora/ice;',
        instruction12: 'naziv institucije u kojoj radi;',
        instruction13: 'kontakt autora (e-mail, telefon i adresa), s navođenjem autora za korespondenciju u svim fazama recenziranja i objavljivanja, ukoliko je više autora;',
        instruction14: 'naslov rada na hrvatskom i engleskom jeziku;',
        instruction15: 'sažetak (opći prikaz teme, metodologiju, rezultate i zaključak - do 250 riječi) i ključne riječi (do pet ključnih riječi) na hrvatskom i engleskom jeziku;',
        instruction16: 'popis literature.',
        instruction17: 'Radove treba pisati u MS Wordu, a pohraniti u „.doc ili .docx“ formatu. Obvezna je uporaba fonta Times New Roman CE, veličina 12, prored 1,5. U pozivnim bilješkama veličina slova je 10, a prored jednostruki. Veličina stranice je A4, gornja i donja margina 25 mm, a lijeva i desna 20 mm.',
        instruction18: 'Rad i svi prilozi dostavljaju se isključivo u elektroničkom obliku. Preporučena duljina teksta je u rasponu od 10 do 15 autorskih kartica (1 kartica sadrži 1800 znakova s razmacima - 30 redaka sa 60 znakova u retku), uključujući sve dijelove rada.',
        instruction19: 'U pisanju rada autori primjenjuju APA stil referenciranja, koji podrazumijeva navođenje referenci u tekstu, dok se u pozivnim bilješkama navode ostale napomene i podaci, ukoliko ih ima, a svi ostali podaci u bibliografiji.',
        instruction20: 'Radove koji ne zadovoljavaju kriterije opisane u navedenim uputama, uredništvo časopisa će vratiti autoru.',
        instruction21: 'Časopis se pridržava najviših etičkih standarda slijedeći smjernice najbolje prakse u svim stadijima pripreme i objave rada. Pretpostavlja se da radovi nisu objavljeni u drugim publikacijama. Autori su suglasni s objavom radova na web stranici časopisa, a časopis zadržava i sva ostala prava.',
        instruction22: 'Radovi se dostavljaju elektroničkom poštom na adresu: ',
        editorial: 'Uredničko vijeće',
        internacional: 'Međunarodno znanstveno vijeće',
        contact: 'Kontaktirajte nas',
        editor: 'Za nakladnika',
        editors: 'Za sunakladnika',
        secretary: 'Glavni urednik',
        no1: 'Br 1',
        no2: 'Br 2',
        instructionsForAuthors: 'Upute autorima',
        instructionsForReferences: 'Upute za reference',
        instructionsForReviewers: 'Upute recenzenti',
        researchEthics: 'Etika istraživanja',
        secretary_2: 'Tajnica',
        proofreadingCroatianLang: 'Lektura hrvatskoga jezika',
        proofreadingEnglishLang: 'Lektura engleskoga jezika',
        graphicPreparationPrinting: 'Grafička priprema i tisak',
        sum: 'Sveučilište u Mostaru, Trg hrvatskih velikana 1, 88000 Mostar, BiH',
        urednicko_vijece: [
            "dr. sc. Sanja Bijakšić, red. prof. (University of Mostar)",
            "dr. sc. Miljenko Brekalo, red. prof. (Institute of Social Sciences Ivo Pilar Zagreb,Osijek)",
            "dr. sc. Željko Holjevac, red. prof.(Zagreb)",
            "dr. sc. Vesna Kazazić, red. prof. (University of Mostar)",
            "dr. sc. Stipe Kutleša, red. prof. (Institute of Philosophy, Zagreb)",
            "dr. sc. Ante Pavlović, izv. prof. (Theological institute of Mostar)",
            "dr. sc. Damir Primorac, izv. prof. (University of Split)",
            "dr. sc. Željko Šantić, red. prof. (University of Mostar)",
            "dr. sc. Zoran Tomić, red. prof. (University of Mostar)",
            "dr. sc. Mario Vasilj, red. prof. (University of Mostar)",
        ],
        medunarodno_vijece: [
            "dr. sc. Mladen Ančić, red. prof. (University of Zadar)",
            "dr. sc. Stjepan Damjanović, red. prof. (University of Zagreb)",
            "dr. sc. Jadranka Gvozdanović, red. prof. (University of Heidelberg)",
            "dr. sc. Nevenko Herceg, red. prof. (University of Mostar)",
            "dr. sc. Georg Holzer, izv. prof. (University of Vienna)",
            "dr. sc. Zdenko Klepić, red. prof. (University of Mostar)",
            "dr. sc. Stjepan Krasić, red. prof. (Dubrovnik International University)",
            "dr. sc. Mile Lasić, red. prof. (University of Mostar)",
            "dr. sc. Antun Lučić, red. prof. (University of Mostar)",
            "dr. sc. Nikola Mijatović, red. prof. (University of Zagreb)",
            "dr. sc. Šimun Musa, red. prof. (University of Mostar)",
            "dr. sc. Marko Mustapić, izv. prof. (University of Zagreb)",
            "dr. sc. Zvjezdan Penezić, red. prof. (University of Zadar)",
            "dr. sc. Ivica Šarac, red. prof. (University of Mostar)",
            "dr. sc. Nevio Šetić, red. prof. (University of Pula)",
            "dr. sc. Božo Žepić, red. prof. (University of Mostar)"
        ],
        zoran_tomic: 'dr. sc. Zoran Tomić, red. prof.',
        zeljko_holjevac: 'dr. sc. Željko Holjevac, , red. prof.',
        ivica_music: 'dr. sc. Sanja Bijakšić, red. prof.'
    },
    'en': {
        items: [
            {
                title: "About us",
                link: "#opceInformacije",
                icon: "fas fa-info-circle"
            },
            {
                title: "Editorial Board",
                link: "#urednistvo",
                icon: "fas fa-user-edit"
            },
            {
                title: "Archive",
                link: "#clanci",
                icon: "fas fa-archive"
            },
            {
                title: "Documents",
                link: "#upute",
                icon: "far fa-clipboard"
            },
            {
                title: "Editorial Council",
                link: "#urednici",
                icon: "fas fa-user-friends"
            },
            {
                title: "International Scientific Council",
                link: "#medunarodno",
                icon: "fas fa-users"
            },
            // {
            //   title: "Pretraga Code of Ethics",
            //   link: "#"
            // },
            {
                title: "Contact",
                link: "#kontakt",
                icon: "fas fa-phone-square"
            }
        ],
        about: 'About us',
        about_text: ``,
        about_text1: `Mostariensia is a journal for social sciences and humanities edited and published by the
University of Mostar and co-published by Institute of Social Sciences Ivo Pilar. The journal is
published biannually. We welcome submissions ranging from social sciences and humanities
to those of interdisciplinary and multidisciplinary character. Only unpublished papers will be
accepted for publication in the journal. We will not consider submissions that are under
consideration for other journals. Papers must be clearly written in Croatian, English or
German.`,
        about_text2: `The magazine is published twice a year.<br>
      The journal is referenced in the following databases:<br>
      
      Hrčak - Portal of scientific journals of the Republic of Croatia <a href="https://hrcak.srce.hr/" target="_blank">https://hrcak.srce.hr/</a><br>
      
      EBSCO - Full text online library (<a href="www.ebschohost.com" target="_blank">www.ebschohost.com</a>)<br>
      CEEOL - Central and Eastern European Online Library (<a href="www.ceeol.com" target="_blank">www.ceeol.com</a>)<br>
      
      Slavus - Slavic Humanities Index (<a href="www.slavus.ca" target="_blank">www.slavus.ca</a>)<br>
      
      ISSN 1023-8638`,
        editional_board: 'Editorial Board',
        archive: 'Archive',
        instruction: 'Documents',
        instruction1: 'Časopis South Eastern European Journal of Communication znanstvena je publikacija otvorenog pristupa koja objavljuje recenzirane radove. Dostavljeni radovi, koji zadovoljavaju upute o pisanju, podliježu dvostrukoj anonimnoj recenziji - ocjenjuju ih dva recenzenta, pri čemu su identiteti autora i recenzenata obostrano nepoznati. Ukoliko je ocjena jednog od recenzenata negativna, rad će biti upućen na dodatnu recenziju. Temeljem recenzije uredništvo donosi odluku o konačnoj kategorizaciji rada na način njegova prihvaćanja, traženja dorada ili ispravaka, ili odbijanja. Dorađene ili ispravljene radove autori trebaju poslati Uredništvu najkasnije u roku od 8 dana od primitka primjedbi.',
        instruction2: 'U časopisu se objavljuju sljedeće kategorije radova:',
        instruction3: 'izvorni znanstveni rad,',
        instruction4: 'prethodno priopćenje,',
        instruction5: 'pregledni znanstveni rad,',
        instruction6: 'stručni rad.',
        instruction7: 'Iznimno, Uredništvo časopisa može prihvatiti i drugu vrstu rada koji nije kategoriziran, ako se isti bude smatrao korisnim za čitateljstvo.',
        instruction8: 'Svi radovi trebaju biti pisani na engleskom ili hrvatskom jeziku. Dostavljeni tekstovi trebaju biti lektorirani. Obvezno je dostaviti i podatke o prevoditelju i/ili lektoru: ime i prezime, naziv i adresu institucije, adresu e-pošte i broj telefona.',
        instruction9: 'Pri dostavljanju rada autor dostavlja i izjavu da je rad autorsko djelo, da nije već objavljen, niti ponuđen drugom časopisu na objavljivanje. Izneseni stavovi u radu isključiva su odgovornost autora.',
        instruction10: 'Svaki rad treba sadržavati:',
        instruction11: 'ime i prezime i akademski stupanj autora/ice;',
        instruction12: 'naziv institucije u kojoj radi;',
        instruction13: 'kontakt autora (e-mail, telefon i adresa), s navođenjem autora za korespondenciju u svim fazama recenziranja i objavljivanja, ukoliko je više autora;',
        instruction14: 'naslov rada na hrvatskom i engleskom jeziku;',
        instruction15: 'sažetak (opći prikaz teme, metodologiju, rezultate i zaključak - do 250 riječi) i ključne riječi (do pet ključnih riječi) na hrvatskom i engleskom jeziku;',
        instruction16: 'popis literature.',
        instruction17: 'Radove treba pisati u MS Wordu, a pohraniti u „.doc ili .docx“ formatu. Obvezna je uporaba fonta Times New Roman CE, veličina 12, prored 1,5. U pozivnim bilješkama veličina slova je 10, a prored jednostruki. Veličina stranice je A4, gornja i donja margina 25 mm, a lijeva i desna 20 mm.',
        instruction18: 'Rad i svi prilozi dostavljaju se isključivo u elektroničkom obliku. Preporučena duljina teksta je u rasponu od 10 do 15 autorskih kartica (1 kartica sadrži 1800 znakova s razmacima - 30 redaka sa 60 znakova u retku), uključujući sve dijelove rada.',
        instruction19: 'U pisanju rada autori primjenjuju APA stil referenciranja, koji podrazumijeva navođenje referenci u tekstu, dok se u pozivnim bilješkama navode ostale napomene i podaci, ukoliko ih ima, a svi ostali podaci u bibliografiji.',
        instruction20: 'Radove koji ne zadovoljavaju kriterije opisane u navedenim uputama, uredništvo časopisa će vratiti autoru.',
        instruction21: 'Časopis se pridržava najviših etičkih standarda slijedeći smjernice najbolje prakse u svim stadijima pripreme i objave rada. Pretpostavlja se da radovi nisu objavljeni u drugim publikacijama. Autori su suglasni s objavom radova na web stranici časopisa, a časopis zadržava i sva ostala prava.',
        instruction22: 'Radovi se dostavljaju elektroničkom poštom na adresu: ',
        editorial: 'Editorial Council',
        internacional: 'International Scientific Council',
        contact: 'Contact',
        editor: 'For the Publisher',
        editors: 'For the Co-publisher',
        secretary: 'Editor-in-chief',
        no1: 'No. 1',
        no2: 'No. 2',
        instructionsForAuthors: 'Instructions for authors',
        instructionsForReferences: 'Instructions for references',
        instructionsForReviewers: 'Instructions for reviewers',
        researchEthics: 'Research Ethics',
        secretary_2: 'Secretary',
        proofreadingCroatianLang: 'Proofreading of the Croatian language',
        proofreadingEnglishLang: 'Proofreading of the English language',
        graphicPreparationPrinting: 'Graphic preparation and printing',
        sum: 'University of Mostar, Trg hrvatskih velikana 1, 88000 Mostar, Bosnia and Herzegovina',
        urednicko_vijece: [
            "Sanja Bijakšić, PhD, Full Professor (University of Mostar)",
            "Miljenko Brekalo, PhD, Full Professor (Institute of Social Sciences Ivo Pilar Zagreb, Osijek)",
            "Željko Holjevac, PhD, Full Professor (Zagreb)",
            "Vesna Kazazić, PhD, Full Professor (University of Mostar)",
            "Stipe Kutleša, PhD, Full Professor (Institute of Philosophy, Zagreb)",
            "Ante Pavlović, PhD, Associate Professor (Theological institute of Mostar)",
            "Damir Primorac, PhD, Associate Professor (University of Split)",
            "Željko Šantić, PhD, Full Professor (University of Mostar)",
            "Zoran Tomić, PhD, Full Professor (University of Mostar)",
            "Mario Vasilj, PhD, Full Professor (University of Mostar)"
        ],
        medunarodno_vijece: [
            "Mladen Ančić, PhD, Full Professor (University of Zadar)",
            "Stjepan Damjanović, PhD, Full Professor (University of Zagreb)",
            "Jadranka Gvozdanović, PhD, Full Professor (University of Heidelberg)",
            "Nevenko Herceg, PhD, Full Professor (University of Mostar)",
            "Georg Holzer, PhD, Associate Professor (University of Vienna)",
            "Zdenko Klepić, PhD, Full Professor (University of Mostar)",
            "Stjepan Krasić, PhD, Full Professor (Dubrovnik International University)",
            "Mile Lasić, PhD, Full Professor (University of Mostar)",
            "Antun Lučić, PhD, Full Professor (University of Mostar)",
            "Nikola Mijatović, PhD, Full Professor (University of Zagreb)",
            "Šimun Musa, PhD, Full Professor (University of Mostar)",
            "Marko Mustapić, PhD, Associate Professor (University of Zagreb)",
            "Zvjezdan Penezić, PhD, Full Professor (University of Zadar)",
            "Ivica Šarac, PhD, Full Professor (University of Mostar)",
            "Nevio Šetić, PhD, Full Professor (University of Pula)",
            "Božo Žepić, PhD, Full Professor (University of Mostar)"
        ],
        zoran_tomic: 'Zoran Tomić, PhD, Full Professor',
        zeljko_holjevac: 'Željko Holjevac, PhD, Full Professor',
        ivica_music: 'Sanja Bijakšić, PhD, Full Professor'
    }
}
const i18n = new VueI18n({
    locale: 'hr', // set locale
    fallbackLocale: 'hr', // set fallback locale
    messages, // set locale messages
});

export default i18n;